<template>
	<article class="d-front-page-custom relative bg-sand">
		<FrontPageHero
			v-bind="{
				heroTeaser,
				heroTitle,
				heroMedia,
				heroCta,
				searchTitle,
				heroFullWidth,
				heroSmallTitle,
			}"
		/>

		<SvgBridgeIcon
			:class="['absolute bottom-0 left-0 w-full', 'text-sand-dark z-0']"
		/>

		<div class="mx-layout-margin relative">
			<BlockList
				:block-list="contentElements"
				class="w-full my-0 pb-xl"
			/>
		</div>

		<FeedbackForm v-if="activateFeedback" class="mb-lg" />
	</article>
</template>

<script>
import SvgBridgeIcon from '~/assets/svgs/bridge-icon.svg?inline';
import FrontPageHero from '~/components/main/FrontPageHero';
import BlockList from '~/components/main/BlockList';
import FeedbackForm from '~/components/shared/FeedbackForm';

export default {
	name: 'MainFrontPageCustom',

	components: {
		SvgBridgeIcon,
		FrontPageHero,
		BlockList,
		FeedbackForm,
	},

	inheritAttrs: false,

	props: {
		heroTeaser: String,
		heroTitle: String,
		searchTitle: String,
		heroFullWidth: Boolean,
		heroSmallTitle: String,

		heroMedia: {
			type: Object,
			default: () => ({}),
		},
		contentElements: {
			type: Array,
			default: () => [],
		},
		heroCta: {
			type: Array,
			default: () => [],
		},
		activateFeedback: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="postcss">
.d-front-page-custom__bridge {
	z-index: -1;
}
</style>
