var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"d-front-page-custom relative bg-sand"},[_c('FrontPageHero',_vm._b({},'FrontPageHero',{
			heroTeaser: _vm.heroTeaser,
			heroTitle: _vm.heroTitle,
			heroMedia: _vm.heroMedia,
			heroCta: _vm.heroCta,
			searchTitle: _vm.searchTitle,
			heroFullWidth: _vm.heroFullWidth,
			heroSmallTitle: _vm.heroSmallTitle,
		},false)),_vm._v(" "),_c('SvgBridgeIcon',{class:['absolute bottom-0 left-0 w-full', 'text-sand-dark z-0']}),_vm._v(" "),_c('div',{staticClass:"mx-layout-margin relative"},[_c('BlockList',{staticClass:"w-full my-0 pb-xl",attrs:{"block-list":_vm.contentElements}})],1),_vm._v(" "),(_vm.activateFeedback)?_c('FeedbackForm',{staticClass:"mb-lg"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }