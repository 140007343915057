var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-front-page-hero"},[(_vm.heroFullWidth)?_c('FrontPageHeroFullWidth',_vm._b({},'FrontPageHeroFullWidth',{
			heroTeaser: _vm.heroTeaser,
			heroTitle: _vm.heroTitle,
			heroMedia: _vm.heroMedia,
			heroCta: _vm.heroCta,
			searchTitle: _vm.searchTitle,
			heroFullWidth: _vm.heroFullWidth,
			heroSmallTitle: _vm.heroSmallTitle,
		},false)):_c('div',{staticClass:"bg-primary text-text z-40 relative"},[_c('div',{staticClass:"relative flex flex-col >=1024:flex-row w-full"},[_c('div',{class:[
					'c-front-page-hero__content',
					'>=1440:mt-layout-margin px-layout-margin',
					'flex flex-col justify-center',

					{
						'c-front-page-hero__content--image >=1024:pb-xl >=1024:w-1/2':
							_vm.heroMedia,
					} ]},[_c('BaseH1',{class:{
						'>=1024:w-6/12': !_vm.heroMedia,
						'mb-sm >=1024:mb-0': _vm.heroMedia,
					},domProps:{"textContent":_vm._s(_vm.heroTitle)}}),_vm._v(" "),(_vm.heroTeaser)?_c('div',{class:[
						'hidden >=1024:block text-h3 mt-sm',
						{ '>=1024:w-6/12': !_vm.heroMedia } ],domProps:{"innerHTML":_vm._s(_vm.heroTeaser)}}):_vm._e(),_vm._v(" "),(_vm.heroCta && _vm.heroCta.length > 0)?_c('NuxtLinkExt',{staticClass:"hidden >=1024:block w-fit",attrs:{"to":_vm.heroCta[0].url,"target":_vm.heroCta[0].target}},[_c('BaseButton',{staticClass:"w-fit mt-md",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.heroCta[0].target === '_blank')?_c('SvgExternalArrowIcon',{staticClass:"w-14 h-12 text-white"}):_c('SvgArrowIcon',{staticClass:"w-14 h-12 text-white"})]},proxy:true}],null,false,1777054432)})],1):_vm._e()],1),_vm._v(" "),(_vm.heroMedia)?_c('div',{class:[
					'c-front-page-hero__media',
					'relative w-full bg-primary-medium',
					'>=1024:flex-1 >=1024:w-1/2' ]},[(
						_vm.heroMedia.fileType === 'Image' && _vm.heroMedia.cropUrl
					)?_c('UmbracoImageExt',{class:['bg-primary-medium relative'],attrs:{"alt":_vm.heroMedia.altText,"widths":[320, 480, 720, 480 * 2, 720 * 2],"source-url":_vm.heroMedia.cropUrl || _vm.heroMedia.url,"source-width":_vm.heroMedia.width,"source-height":_vm.heroMedia.height,"aspect-ratio":720 / 643}}):_vm._e(),_vm._v(" "),(
						_vm.heroMedia.fileType === 'Video' && _vm.heroMedia.cropUrl
					)?_c('div',{staticClass:"c-front-page-hero__video h-full"},[_c('video',{ref:"heroVideo",staticClass:"h-full absolute w-full object-cover",attrs:{"autoplay":"","muted":"","playsInline":"","loop":"","preload":"none","crossOrigin":"anonymous","aria-label":"stemningsvideo"},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.heroMedia.cropUrl,"type":"video/mp4"}}),_vm._v("\n\t\t\t\t\t\tYour browser does not support the video tag.\n\t\t\t\t\t")]),_vm._v(" "),(_vm.videoIsPlaying)?_c('BaseButton',{staticClass:"\n\t\t\t\t\t\t\tc-front-page-hero__toggle\n\t\t\t\t\t\t\tabsolute\n\t\t\t\t\t\t\tbottom-16\n\t\t\t\t\t\t\tright-16\n\t\t\t\t\t\t\tz-10\n\t\t\t\t\t\t\tmobile:bottom-48\n\t\t\t\t\t\t\ttablet:bottom-24\n\t\t\t\t\t\t",attrs:{"aria-label":"Sæt videoen på pause"},on:{"click":function($event){return _vm.toggleVideo()}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgPauseIcon',{staticClass:"text-primary-button w-24 h-24"})]},proxy:true}],null,false,3682315014)}):_c('BaseButton',{staticClass:"\n\t\t\t\t\t\t\tc-front-page-hero__toggle\n\t\t\t\t\t\t\tabsolute\n\t\t\t\t\t\t\tbottom-16\n\t\t\t\t\t\t\tright-16\n\t\t\t\t\t\t\tz-10\n\t\t\t\t\t\t\tmobile:bottom-48\n\t\t\t\t\t\t\ttablet:bottom-24\n\t\t\t\t\t\t",attrs:{"aria-label":"Afspil videoen"},on:{"click":function($event){return _vm.toggleVideo()}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\tw-24\n\t\t\t\t\t\t\t\t\th-24\n\t\t\t\t\t\t\t\t\tflex\n\t\t\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\t\t\tjustify-center\n\t\t\t\t\t\t\t\t"},[_c('SvgPlayIcon',{staticClass:"text-primary-button w-16 ml-2"})],1)]},proxy:true}],null,false,1113232243)})],1):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:">=1024:hidden px-layout-margin pb-lg"},[(_vm.heroTeaser)?_c('div',{class:[
						'text-h3 mt-sm',
						{ '>=1024:w-6/12': !_vm.heroMedia } ],domProps:{"innerHTML":_vm._s(_vm.heroTeaser)}}):_vm._e(),_vm._v(" "),(_vm.heroCta && _vm.heroCta.length > 0)?_c('NuxtLinkExt',{staticClass:"w-fit",attrs:{"to":_vm.heroCta[0].url,"target":_vm.heroCta[0].target}},[_c('BaseButton',{staticClass:"w-fit mt-md",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.heroCta[0].target === '_blank')?_c('SvgExternalArrowIcon',{staticClass:"w-14 h-12 text-white"}):_c('SvgArrowIcon',{staticClass:"w-14 h-12 text-white"})]},proxy:true}],null,false,1777054432)})],1):_vm._e()],1)]),_vm._v(" "),_c('BaseInputText',{staticClass:"\n\t\t\t\thidden\n\t\t\t\t>=1024:block\n\t\t\t\tw-full\n\t\t\t\t>=1024:w-8col\n\t\t\t\tmx-auto\n\t\t\t\t-mt-35\n\t\t\t\tc-front-page-hero-dropdown__shadow\n\t\t\t\trounded-full\n\t\t\t\ttransform-gpu\n\t\t\t\tz-20\n\t\t\t",attrs:{"id":"frontpage-search","wrapper-id":"frontpage-search-wrapper","tall":"","autocomplete":"off","placeholder":_vm.searchTitle},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('button',{class:[
						'absolute top-1/2 right-16',
						'transform -translate-y-1/2',
						'rounded-full bg-extra w-40 h-40',
						'flex justify-center items-center' ],attrs:{"aria-label":"Søg"}},[_c('SvgSearch',{staticClass:"w-16 h-16 text-white"})],1)]},proxy:true}])}),_vm._v(" "),(_vm.$scopedSlots.after)?_c('div',{staticClass:"px-layout-margin pt-lg pb-xl"},[_vm._t("after")],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }