<template>
	<div class="c-front-page-hero">
		<div class="bg-primary text-text z-40 relative">
			<div class="relative flex flex-col >=1024:flex-row w-full">
				<!-- Content -->
				<div
					:class="[
						'absolute z-10 w-6col left-md2',
						{
							'top-1/2 transform -translate-y-1/2':
								!heroCta && !heroTeaser,
							'bottom-md2': heroCta || heroTeaser,
						},
					]"
				>
					<span
						v-if="!heroCta && !heroTeaser"
						class="text-white <1024:hidden block text-h2"
					>
						{{ heroSmallTitle }}
					</span>
					<h1
						v-if="!heroCta && !heroTeaser"
						class="
							text-white
							<1024:hidden
							text-h1-frontpage
							font-semibold
							block
						"
					>
						{{ heroTitle }}
					</h1>
					<div
						v-if="heroTitle && heroTeaser"
						class="bg-white rounded-md p-40 <1024:hidden relative"
					>
						<BaseH1
							v-if="heroTitle"
							:class="[
								'font-semibold mb-24',
								{
									' text-h2 ': heroCta || heroTeaser,
									'text-h1': !heroCta && !heroTeaser,
								},
							]"
						>
							{{ heroTitle }}
						</BaseH1>
						<p v-if="heroTeaser">{{ heroTeaser }}</p>
						<NuxtLinkExt
							v-if="heroCta && heroCta.length > 0"
							class="hidden >=1024:block w-fit"
							:to="heroCta[0].url"
							:target="heroCta[0].target"
						>
							<BaseButton tag="div" class="w-fit mt-md">
								<template #icon>
									<SvgExternalArrowIcon
										v-if="heroCta[0].target === '_blank'"
										class="w-14 h-12 text-white"
									/>
									<SvgArrowIcon
										v-else
										class="w-14 h-12 text-white"
									/>
								</template>
							</BaseButton>
						</NuxtLinkExt>
					</div>
				</div>
				<span
					v-if="!heroCta && !heroTeaser"
					class="
						>=1024:hidden
						ml-16
						mr-16
						mt-172
						font-semibold
						text-h2
						block
					"
				>
					{{ heroSmallTitle }}
				</span>
				<h1
					v-if="heroTitle && !heroCta && !heroTeaser"
					:class="[
						'>=1024:hidden ml-16 mr-16 mb-18 block text-h1',
						{ 'mt-152': heroCta || heroTeaser },
					]"
					v-html="heroTitle"
				></h1>

				<!-- Image -->
				<div
					v-if="heroMedia"
					:class="[
						'w-full',
						'c-front-page-hero__media',
						'relative w-full bg-primary-medium',
						'>=1024:flex-1 >=1024:w-full',
					]"
				>
					<!-- Image -->
					<UmbracoImageExt
						v-if="
							heroMedia.fileType === 'Image' && heroMedia.cropUrl
						"
						:alt="heroMedia.altText"
						:widths="[
							320,
							480,
							720,
							1200,
							1400,
							1920,
							1200 * 2,
							1400 * 2,
							1920 * 2,
						]"
						:source-url="heroMedia.cropUrl || heroMedia.url"
						:source-width="heroMedia.width"
						:source-height="heroMedia.height"
						:aspect-ratio="36 / 19"
						:class="[
							'bg-primary-medium relative w-full hidden >=1024:block',
						]"
					/>
					<UmbracoImageExt
						v-if="
							heroMedia.fileType === 'Image' && heroMedia.cropUrl
						"
						:alt="heroMedia.altText"
						:widths="[
							320,
							480,
							720,
							1200,
							1400,
							1920,
							1200 * 2,
							1400 * 2,
							1920 * 2,
						]"
						:source-url="heroMedia.cropUrl || heroMedia.url"
						:source-width="heroMedia.width"
						:source-height="heroMedia.height"
						:aspect-ratio="4 / 3"
						:class="[
							'bg-primary-medium relative w-full >=1024:hidden',
						]"
					/>
					<!-- Video -->
					<div
						v-if="
							heroMedia.fileType === 'Video' && heroMedia.cropUrl
						"
						class="c-front-page-hero__video relative w-full"
					>
						<video
							ref="heroVideo"
							autoplay
							muted
							playsInline
							loop
							preload="none"
							crossOrigin="anonymous"
							class="absolute h-full w-full object-cover"
							aria-label="stemningsvideo"
						>
							<source :src="heroMedia.cropUrl" type="video/mp4" />
							Your browser does not support the video tag.
						</video>
						<div
							v-if="!heroTeaser && !heroCta"
							class="
								c-front-page-hero__gradient
								w-full
								absolute
								h-full
								<1024:hidden
							"
						></div>
						<BaseButton
							v-if="videoIsPlaying"
							class="
								c-front-page-hero__toggle
								absolute
								bottom-16
								right-16
								z-10
								mobile:bottom-48
								tablet:bottom-24
							"
							aria-label="Sæt videoen på pause"
							@click="toggleVideo()"
						>
							<template #icon>
								<SvgPauseIcon
									class="text-primary-button w-24 h-24"
								/>
							</template>
						</BaseButton>
						<BaseButton
							v-else
							class="
								c-front-page-hero__toggle
								absolute
								bottom-16
								right-16
								z-10
								mobile:bottom-48
								tablet:bottom-24
							"
							aria-label="Afspil videoen"
							@click="toggleVideo()"
						>
							<template #icon>
								<div
									class="
										w-24
										h-24
										flex
										items-center
										justify-center
									"
								>
									<SvgPlayIcon
										class="text-primary-button w-16 ml-2"
									/>
								</div>
							</template>
						</BaseButton>
					</div>
				</div>

				<!-- Mobile: Teaser and link -->
				<div class=">=1024:hidden px-layout-margin pb-lg">
					<div
						v-if="heroTeaser"
						:class="[
							'text-h3 mt-sm',
							{ '>=1024:w-6/12': !heroMedia },
						]"
						v-html="heroTeaser"
					></div>

					<NuxtLinkExt
						v-if="heroCta && heroCta.length > 0"
						class="w-fit"
						:to="heroCta[0].url"
						:target="heroCta[0].target"
					>
						<BaseButton tag="div" class="w-fit mt-md">
							<template #icon>
								<SvgExternalArrowIcon
									v-if="heroCta[0].target === '_blank'"
									class="w-14 h-12 text-white"
								/>
								<SvgArrowIcon
									v-else
									class="w-14 h-12 text-white"
								/>
							</template>
						</BaseButton>
					</NuxtLinkExt>
				</div>
			</div>

			<BaseInputText
				id="frontpage-search"
				wrapper-id="frontpage-search-wrapper"
				tall
				:class="[
					'c-front-page-hero-dropdown__shadow hidden >=1024:block w-full >=1024:w-8col mx-auto  rounded-full transform-gpu z-20',
					{
						' mt-lg': heroCta || heroTeaser,
						'-mt-35': !heroCta && !heroTeaser,
					},
				]"
				autocomplete="off"
				:placeholder="searchTitle"
			>
				<template #button>
					<button
						:class="[
							'absolute top-1/2 right-16',
							'transform -translate-y-1/2',
							'rounded-full bg-extra w-40 h-40',
							'flex justify-center items-center',
						]"
						aria-label="Søg"
					>
						<SvgSearch class="w-16 h-16 text-white" />
					</button>
				</template>
			</BaseInputText>

			<div v-if="$scopedSlots.after" class="px-layout-margin pt-lg pb-xl">
				<slot name="after"></slot>
			</div>
		</div>
	</div>
</template>

<script>
import UmbracoImageExt from '~/components/shared/UmbracoImageExt.vue';
import SvgPlayIcon from '~/assets/svgs/play-icon.svg?inline';
import SvgPauseIcon from '~/assets/svgs/pause-icon.svg?inline';
import SvgArrowIcon from '~/assets/svgs/arrow-icon.svg?inline';
import SvgExternalArrowIcon from '~/assets/svgs/external-arrow-icon.svg?inline';
import SvgSearch from '~/assets/svgs/search-icon.svg?inline';

export default {
	name: 'FrontPageHeroFullWidth',
	components: {
		UmbracoImageExt,
		SvgArrowIcon,
		SvgExternalArrowIcon,
		SvgSearch,
		SvgPlayIcon,
		SvgPauseIcon,
	},
	props: {
		heroTitle: {
			type: String,
			required: true,
		},
		heroTeaser: {
			type: String,
			required: false,
		},
		searchTitle: {
			type: String,
			required: false,
		},
		image: {
			type: Object,
			required: false,
		},
		heroMedia: {
			type: Object,
			required: false,
		},
		heroCta: {
			type: Array,
			required: false,
		},
		heroFullWidth: {
			type: Boolean,
		},
		heroSmallTitle: {
			type: String,
		},
	},
	data() {
		return {
			videoIsPlaying: true,
		};
	},
	methods: {
		toggleVideo() {
			this.videoIsPlaying = !this.videoIsPlaying;
			if (this.videoIsPlaying) {
				this.$refs.heroVideo?.play();
			} else {
				this.$refs.heroVideo?.pause();
			}
		},
	},
};
</script>

<style lang="postcss">
.c-front-page-hero-dropdown__shadow {
	box-shadow: 0 0 25px rgba(22, 103, 135, 0.1);
}

@screen <1024 {
	.c-front-page-hero__video {
		aspect-ratio: 4 / 3;
	}
}

@screen >=1024 {
	.c-front-page-hero__video {
		height: calc(100vh - var(--theme-spacing-lg));
		max-height: 800px;
	}
}

.c-front-page-hero__toggle:before {
	@apply bg-white !important;
}

@screen >=1024 {
	.c-front-page-hero__media {
		padding-bottom: 0;
	}
}

.c-front-page-hero__content {
	padding-top: 158px !important;
	padding-top: calc(
		158px - var(--theme-spacing-lg--sm) +
			var(--theme-spacing-lg, var(--theme-spacing-lg--sm))
	) !important;
}

@screen >=1024 {
	.c-front-page-hero__content {
		padding-top: 166px !important;
		padding-top: calc(
			166px - var(--theme-spacing-lg--md) +
				var(--theme-spacing-lg, var(--theme-spacing-lg--sm))
		) !important;
	}
}

@screen >=1440 {
	.c-front-page-hero__content {
		padding-top: 152px !important;
		padding-top: calc(
			152px - var(--theme-spacing-lg--md) +
				var(--theme-spacing-lg, var(--theme-spacing-lg--sm))
		) !important;
	}
}
.c-front-page-hero__gradient {
	background: linear-gradient(
		90deg,
		#166787 0%,
		rgba(22, 103, 135, 0) 64.44%
	);
}
</style>
